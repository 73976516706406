import _v2 from "./v35.js";
import _sha2 from "./sha1.js";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _v = _interopRequireDefault(_v2);
var _sha = _interopRequireDefault(_sha2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const v5 = (0, _v.default)("v5", 80, _sha.default);
var _default = v5;
exports.default = _default;
export default exports;