import _v2 from "./v35.js";
import _md2 from "./md5.js";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _v = _interopRequireDefault(_v2);
var _md = _interopRequireDefault(_md2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const v3 = (0, _v.default)("v3", 48, _md.default);
var _default = v3;
exports.default = _default;
export default exports;