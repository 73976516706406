import _regex2 from "./regex.js";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _regex = _interopRequireDefault(_regex2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function validate(uuid) {
  return typeof uuid === "string" && _regex.default.test(uuid);
}
var _default = validate;
exports.default = _default;
export default exports;